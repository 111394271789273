div,
label,
span,
input,
textarea,
select {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px !important;
}
body {
  overflow: hidden;
}
html {
  overflow-y: auto;
}

/* .inner-label-results {
  position: absolute;
  background: #fff;
  top: 0;
}

.display_results {
  position: relative;
  z-index: 100;
} */

.background-white {
  background: #fff !important;
}

.click_to_link {
  padding: 0px 0px 0px 5px;
  margin: 5px 0px 0px 0px;
}
.link_customer {
  padding: 5px;
  color: grey;
}

.link_customer:hover {
  background: #ececec;
  cursor: pointer;
  color: #333;
  font-weight: 700;
}

.top_layer_modal {
  z-index: 1111 !important;
}
.inactive_selection:hover {
  background: #ececec;
  color: #006699;
  font-weight: 600;
  cursor: pointer;
}
.inactive_selection,
.active_selection {
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
}

.inactive_selection {
  background: #fff;
  font-weight: 400;
}
.active_selection {
  background: #ececec;
  color: #006699;
  font-weight: 600;
}

.mask-profile-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.4);
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-height-2 {
  line-height: 2em;
}

.map_iframe {
  height: 71vh;
}
.page_logo {
  width: 150px;
}
.line-height-0-8 {
  line-height: 0.8em;
}

.line-height-0-5 {
  line-height: 0.5em;
}

.profile_logo {
  width: 150px;
  height: 150px;
  /* position: absolute; */
  /* bottom: -40px; */
  /* left: 20px; */
  z-index: 100;
}

.profile_cover {
  position: relative;
  /* height: 40vh; */
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px 80px 0px;
}

.companyName {
  margin-left: 10px;
}

.sanctions-msg {
  margin-top: -80px !important;
}

.item-box-home {
  position: relative;
}

.mask-home-items {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255, 0.6);
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-100px {
  width: 100px !important;
}

.amount-input {
  height: 2em !important;
  background: #fff;
  width: 100%;
}
.delete-beneficiary {
  background: #ff3232;
  border: thin solid #ff3232;
  border-radius: 5px;
  padding: 2px 0px 10px 0px;
  height: 2em;
  width: 80px;
  transition: 0.5s;
  margin-left: 5px;
}
.delete-beneficiary:hover {
  background: red;
  border: thin solid red;
  cursor: pointer;
}

.add-beneficiary {
  background: #fff;
  border: thin solid #dcdcdc;
  border-radius: 5px;
  padding: 2px 0px 10px 0px;
  height: 2em;
  width: 80px;
  transition: 0.5s;
  margin-left: 5px;
}

.add-beneficiary:hover {
  background: #069;
  color: #fff !important;
  cursor: pointer;
  border: thin solid #069;
}
.search-fields-box {
  position: relative;
}

.return-results {
  bottom: 0px;
  left: 0px;
  max-height: 300px;
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.logo-topus {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  float: left;
  margin-right: 10px;
}
.header-container-topups {
  border-bottom: thin solid #ececec;
  padding: 0px 0px 15px 0px;
}

.m-left {
  margin-left: 7px;
}

.top-up-fields {
  height: 2em !important;
  background: #fff;
}
.color-transparent {
  color: transparent !important;
}
.reason {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0px;
  background: #fff !important;
  height: 150px;
}

.tool_tips {
  font-size: 12px !important;
  padding: 2px 10px 2px 10px !important;
}

.customer-row:hover {
  background: #ececec;
}
.online-pos-active {
  background: #ececec;
  cursor: pointer;
  font-weight: 700;
}

.online-pos,
.online-pos-active {
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  border: thin solid #ececec;
}

.online-pos:hover {
  background: #ececec;
  cursor: pointer;
  font-weight: 700;
}

.icon {
  margin-right: 15px;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  text-align: left !important;
}

.line-height-15 {
  line-height: 15pt !important;
}
.btn-w-100 {
  width: auto;
}
.tip-text {
  display: none;
}
.dot {
  height: 10px;
  width: 10px;
  border-radius: 100px;
  background: #4ca64c;
  position: absolute;
  top: -2px;
  left: 3px;
  z-index: 1001;
}
.mobile_order_notifications {
  display: none;
}

.deliver-message-mobile {
  line-height: 15pt;
}

.drawer {
  z-index: 1001 !important;
}
.drawer-menu-items {
  line-height: 25pt !important;
}
.item-attributes {
  line-height: 13pt !important;
}

.orders-delivered-btn {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  color: #fff;
  background: #4ca64c;
  border: thin solid #4ca64c;
}
.orders-delivered-btn:hover {
  border: thin solid green;
  background: green;
}

.est_one {
  padding: 0px 0px 10px 0px !important;
}

.est_two {
  padding: 0px 0px 0px 10px !important;
}

.co-za {
  padding: 3px 10px 5px 10px;
  border-radius: 3px;
  background: #39c;
  border: thin solid #39c;
  margin: 0px 5px 5px 0px;
  color: #fff;
}

.mailer-media {
  background-size: cover;
  background-repeat: no-repeat;
  height: 60px;
  width: 60px;
  background-position: center;
  border-radius: 5px;
}

.div-flex {
  display: flex;
  flex-wrap: wrap;
  border: thin solid red;
}

.update_address_margin_right {
  margin-right: 15px;
}
.another-address {
  padding: 10px;
  border: thin dashed #39c !important;
  border-radius: 10px;
  width: 100%;
  color: #39c;
}

.another-address:hover {
  background: #069;
  cursor: pointer;
  color: #fff;
}
.address-active {
  background: #ececec;
  color: #069;
  font-weight: 600;
}
.address,
.address-active {
  border: thin solid #ececec;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}
.address-active:hover {
  background: #dcdcdc;
  cursor: pointer !important;
}
.address:hover {
  background: #ececec;
  cursor: pointer;
}
.font-size-15 {
  font-size: 15px !important;
}
.track-rails-orange,
.track-rails-green,
.track-rails-blue {
  width: 100% !important;
  margin-top: 10px;
}

.track-rails-orange {
  border-bottom: 2.5px solid darkorange;
}

.track-rails-blue {
  border-bottom: 2.5px solid #069;
}

.track-rails-green {
  border-bottom: 2.5px solid #4ca64c;
}
.notifications-belt-see:hover {
  box-shadow: #ececec 5px 5px 5px;
  border-radius: 10px;
  cursor: pointer;
}
.notifications-belt {
  background: #f3f4f4 !important;
  border-radius: 10px;
  cursor: pointer;
}
.font-size-10 {
  font-size: 10px !important;
}
.padding-3 {
  padding: 3px !important;
}
.orders-input {
  height: 2.1em !important;
}
.orders-btn {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  color: #333;
  background: #f4f3f3;
  border: thin solid #f4f3f3;
}
.orders-btn:hover {
  border: thin solid #39c;
  color: #39c;
  cursor: pointer;
}
.h-container-size {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background: #f4f3f3;
  border: thin solid #ececec;
  margin: 0px 5px 5px 0px;
}

.order_notifications {
  padding: 15px;
  border-radius: 10px;
  position: fixed;
  left: 30px;
  bottom: 30px;
  background: #333;
  /* width: 300px; */
  z-index: 1000;
}

.azania-virtual-black .content {
  padding: 10px;
  height: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.azania-virtual-black .header {
  width: 100%;
  padding: 5px;
  background: #000;
  border-radius: 5px 5px 0px 0px;
  line-height: 15px;
}

.azania-virtual-black {
  background: #0c0c0c;
  width: 100%;
  border-radius: 5px;
}
.azania-virtual-black:hover {
  background: #000;
  cursor: pointer;
}

.azania-virtual-green .content {
  padding: 10px;
  height: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.azania-virtual-green .header {
  width: 100%;
  padding: 5px;
  background: #000;
  border-radius: 5px 5px 0px 0px;
  line-height: 15px;
}

.azania-virtual-green {
  background: #66b266;
  width: 100%;
  border-radius: 5px;
}
.azania-virtual-green:hover {
  background: #4ca64c;
  cursor: pointer;
}

.azania-virtual-basic .content {
  padding: 10px;
  height: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.azania-virtual-basic .header {
  width: 100%;
  padding: 5px;
  background: #000;
  border-radius: 5px 5px 0px 0px;
  line-height: 15px;
}

.azania-virtual-basic {
  background: #dcdcdc;
  width: 100%;
  border-radius: 5px;
  /* border: 3px solid #000; */
}
.azania-virtual-basic:hover {
  background: silver;
  cursor: pointer;
}

.food_type_search {
  border: thin solid #f4f3f3;
  background: #f4f3f3;
  border-radius: 3px;
  padding: 5px 13px 5px 13px;
  color: #39c;
}
.food_type_search:hover {
  border: thin solid #39c;
  cursor: pointer;
}
.container-search {
  padding: 0px 0px 20px 0px;
}
.search-results-padding,
.search-results-padding-companies {
  padding: 10px 0px 10px 15px !important;
}
.search-results-padding:hover {
  background: #f4f3f3;
  cursor: pointer;
}
.relative-position {
  position: relative !important;
  display: flex !important;
  align-items: flex-end !important;
}
.sub-live-search-results::-webkit-scrollbar {
  width: 10px !important;
}

.sub-live-search-results::-webkit-scrollbar-track {
  background: #f4f3f3;
}

.sub-live-search-results::-webkit-scrollbar-thumb {
  background: #ececec;
  border: thin solid #dcdcdc;
  border-radius: 10px;
}
.sub-live-search-results::-webkit-scrollbar-thumb:hover {
  background: #dcdcdc;
}
.sub-live-search-results {
  max-height: 500px;
  padding: 0px 15px 0px 15px;
  overflow-y: auto;
  overflow-x: hidden;
  border: thin solid #ececec;
  border-radius: 0px 0px 5px 5px;
  border-top: hidden;
  box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
  position: absolute;
  left: 0px;
  background: #fff;
  z-index: 2000 !important;
}
.live-search-results {
  position: relative;
  width: 100% !important;
}

.font-normal {
  font-weight: normal !important;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.store-available,
.store-unavailable {
  border-radius: 5px !important;
  border: hidden !important;
  padding: 2px 10px 2px 10px !important;
  font-weight: normal !important;
}
.store-available {
  background: #e5f2e5 !important;
  color: #4ca64c !important;
}

.store-unavailable {
  background: #ffe5e5 !important;
  color: red !important;
}

.search-buttons {
  border-radius: 5px !important;
  border: hidden !important;
  background: #f4f3f3 !important;
  border: thin solid #f4f3f3 !important;
  padding: 5px 10px 5px 10px !important;
  color: #333 !important;
  font-weight: normal !important;
}
.search-buttons:hover {
  border: thin solid #ececec !important;
  cursor: pointer !important;
}
.close-search {
  position: fixed !important;
  right: 50px !important;
  top: 20px;
  z-index: 1000;
}
.search-modal-background-image {
  background-image: url("https://obarak.co.za/assets/background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.search-field-active {
  margin-top: 0px !important;
}
.search-field-active input {
  border-top: hidden;
  border-right: hidden;
  border-left: hidden;
  border-radius: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}
.border-radius-100 {
  border-radius: 100px !important;
}
.bg-white {
  background: #fff !important;
}
.add-relative-position {
  position: relative;
}
.mask-div label {
  background: #000;
  color: #fff;
  padding: 7px 10px 7px 10px;
  border-radius: 3px;
}

.hide-modal-border {
  border: hidden !important;
}

.modal-z-index {
  z-index: 1061 !important;
}
.mask-div-search {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1060 !important;
  background: rgb(0, 0, 0, 0.8);
  left: 0px;
  top: 0px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mask-div {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1000;
  background: rgb(255, 255, 255, 0.8);
  left: 0px;
  top: 0px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accorder-bg {
  border: thin solid #ececec;
  outline: none !important;
}
.max-height-300 {
  max-height: 300px;
  overflow-y: auto;
}

.checkout-fields-left {
  padding: 0px 0px 0px 5px !important;
}

.checkout-fields-right {
  padding: 0px 5px 0px 0px !important;
}

.remove-btn {
  padding: 5px 7px 5px 7px !important;
  border-radius: 3px !important;
  font-weight: 600 !important;
}
.media_in_count {
  background: #ececec;
  padding: 1px 5px 1px 5px !important;
  border-radius: 3px;
  color: #333;
}
.basket_count {
  background: #4ca64c;
  padding: 1px 5px 1px 5px !important;
  border-radius: 3px;
}
.color-white {
  color: #fff !important;
}
.border-light-grey {
  border: thin solid #ececec;
  box-shadow: none !important;
  outline: hidden !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.color-light-grey {
  color: #dcdcdc !important;
}

.price-inputs {
  height: 3em !important;
  margin-top: 10px;
  background: #fff !important;
  font-size: 12px !important;
  border-radius: 15px !important;
  width: 92%;
  margin-bottom: 5px;
  text-align: center;
}

.price-boxes-disabled {
  border: thin solid #ffe5e5;
  background: #ffe5e5;
  border-radius: 10px;
  padding: 7px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.price-boxes {
  border: thin solid #f4f3f3;
  border-radius: 10px;
  padding: 7px;
  text-align: center;
  width: 100%;
  transition: 0.2s linear;
}
.price-boxes:hover {
  box-shadow: #80808045 1px 5px 11px !important;
  cursor: pointer;
}

.main-price-box {
  padding: 7px !important;
}

.font-medium {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.color-dark-brown {
  color: #631919 !important;
}
.color-light-brown {
  color: #f6b26b !important;
}

.color-yellow {
  color: #cccc00 !important;
}
.color-orange {
  color: #ffa500 !important;
}
.color-maroon {
  color: #800000 !important;
}

.search-input {
  background: #fff !important;
  border: thin solid #ececec !important;
  border-radius: 10px;
}

.email-upload-image {
  background-size: cover;
  height: 140px;
  width: 100%;
  border: thin solid #ececec;
  background-position: center;
}
.cursor {
  cursor: pointer !important;
}
.color-light-black {
  color: #333 !important;
}

.color-black {
  color: #000 !important;
}
.text-left {
  text-align: left !important;
}

.price-fields {
  height: 2.5em !important;
  background: #fff !important;
  font-size: 12px !important;
}
.price-fields-other {
  height: 3.9em !important;
  background: #fff !important;
  /* font-size: 14px !important; */
}

.size-options {
  border: thin solid #ececec;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  background: #fff;
}

.size-options:hover {
  background: #f4f4f4;
  cursor: pointer;
}

.size-options-active {
  background: #f4f4f4;
  cursor: pointer;
  border: thin solid #ececec;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

.color-silver {
  color: silver !important;
}
.no-media-text {
  font-weight: 600;
  font-size: 20px !important;
}
.text-align-left {
  text-align: center !important;
}

.color-light-red {
  color: #ffb2b2 !important;
}
.color-red {
  color: red !important;
}
.img-border-radius {
  border-radius: 5px !important;
}
.media-container,
.media-container-basket,
.media-container-search,
.media-container-home,
.media-container-search-results {
  height: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  background-position: top;
  cursor: pointer;
  opacity: 0.9;
  transition: transform 0.2s; /* Animation */
}

/*.media-container-home:hover {
  transform: scale(
    1.1
  ); 
  z-index: 100;
}*/

.media-container-search-results {
  height: 50px !important;
  width: 50px !important;
  border-radius: 100px;
}

.media-container-search {
  height: 200px !important;
}

.media-container-basket {
  width: 70px !important;
  height: 70px !important;
}

.media-container-home {
  height: 170px !important;
}
.media-container:hover,
.media-container-home:hover,
.media-container-basket:hover,
.media-container-search:hover {
  opacity: 10;
}

.logo-special-div-ban {
  justify-content: start;
  align-items: center;
  display: flex;
}

.logo-special-div {
  justify-content: center;
  align-items: center;
  display: flex;
}
.logo-div-cus {
  height: 40px;
  width: 150px;
  border-radius: 100px;
  background-position: top;
  background-size: contain;
}

.logo-div {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-position: top;
  background-size: cover;
}
.business-name-color-text-blue {
  color: grey !important;
}
.color-green {
  color: #4ca64c !important;
}
.color-grey {
  color: grey;
}
.font-size-12 {
  font-size: 13px !important;
}
.anchor {
  color: #39c;
  text-decoration: none;
}

.anchor:hover {
  color: #069;
  text-decoration: underline;
}
.font-size-17 {
  font-size: 17px !important;
}
.text-bold {
  font-weight: 700 !important;
}

.height-100hv {
  height: 100vh !important;
}

.body,
.package-body {
  background: #e1e8ed !important;
  position: relative !important;
}

.middle-container {
  overflow-x: auto !important;
  height: 100vh;
  /* z-index: 10000 !important; */
}

.text-color-white {
  color: #fff !important;
}
.textUnderLineNone {
  text-decoration: none !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.text-right {
  text-align: right !important;
}

.sub-header {
  color: #657786;
}

.remove-text {
  color: red;
}

.remove-text:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.color-blue {
  color: #3399cc !important;
}

.color-blue-hover:hover {
  color: #069 !important;
  cursor: pointer !important;
}

.text-underline-on-hover:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.message-error {
  width: 100%;
  padding: 15px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  color: #fff;
}

textarea,
input {
  width: 100%;
  background: #ececec;
  border-radius: 5px;
  border: thin solid #dcdcdc;
  padding: 10px;
  outline: none;
  box-shadow: none;
}

textarea {
  height: 15em;
  resize: none !important;
}

input {
  height: 3em !important;
}

.email-icons {
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  color: grey;
  margin-right: 5px;
  float: right;
}

.email-icons:hover {
  background: #ececec;
  cursor: pointer;
}

.float-right {
  float: right !important;
}

.text-normal {
  font-weight: 400 !important;
}
.food-container {
  max-height: 300px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.border-radius-5 {
  border-radius: 5px;
}

.cancel-btn {
  background: #fff !important;
  border-radius: 5px;
  border: thin solid silver;
  padding: 10px 13px 10px 13px;
  text-align: center;
}

.cancel-btn:hover {
  background: #ececec !important;
  cursor: pointer;
}

.disabled-btn {
  background: silver;
  border-radius: 5px;
  border: thin solid silver;
  padding: 10px 13px 10px 13px;
  text-align: center;
  color: #fff;
}

.login-btn {
  background: #ffb732;
  border-radius: 5px;
  border: thin solid #ffb732;
  padding: 10px 13px 10px 13px;
  text-align: center;
  color: #fff;
}

.login-btn:hover {
  background: #ffae19;
  border: thin solid #ffae19;
  cursor: pointer;
}

.karamale-login-btn {
  background: #ff4c4c;
  border-radius: 5px;
  border: thin solid #ff4c4c;
  padding: 10px 13px 10px 13px;
  text-align: center;
  color: #fff;
}

.karamale-login-btn:hover {
  background: #ff3232;
  border: thin solid #ff3232;
  cursor: pointer;
}

.action-btn {
  background: #333;
  border-radius: 5px;
  border: thin solid #333;
  padding: 10px 13px 10px 13px;
  text-align: center;
  color: #fff;
}

.action-btn:hover {
  background: #000;
  border: thin solid #000;
  cursor: pointer;
}

.media-box-upload {
  border: 2px dashed #ececec;
  height: 100px !important;
  width: 100px !important;
  border-radius: 10px;
  background: #fff;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  color: #333;
}

.media-box-upload:hover {
  border: 2px solid #ececec;
  height: 100px !important;
  width: 100px !important;
  border-radius: 10px;
  background: #ececec;
  cursor: pointer;
}

.hide-file-input-type {
  display: none;
}
.text-medium {
  font-weight: 500 !important;
}
.text-blue {
  color: #39c;
}

.text-hover-underline:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.box-container {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}
.text-decoration-none {
  text-decoration: none !important;
}
.container-home,
.container-logged-out {
  max-width: 100% !important;
}

.container-logged-out {
  height: 100vh !important;
}

.nav-container {
  border-right: thin solid #e1e8ed;
  background: #fff;
  padding: 15px !important;
  /* position: fixed; */
  /* width: 18% !important; */
  left: 0;
  margin-right: 0px;
  /* box-shadow: silver 0px 3px 10px; */
}
.nav-container-host {
  background: #fff;
  width: 100%;
}

.side-nav-container-host {
  height: calc(100vh - 149px);
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
}

.side-nav-container-busisness {
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  border-right: thin solid #e1e8ed;
  background: #fff;
  /* position: fixed; */
  z-index: 1000;
  left: 0;
}

.side-nav-container {
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  border-right: thin solid #e1e8ed;
  background: #fff;
  padding: 0px 0px 0px 0px !important;
  /* position: fixed; */
  z-index: 1000;
  /* width: 18% !important; */
  left: 0;
  /* box-shadow: silver 0px 3px 10px; */
}
.r-side-nav {
  width: 100% !important;
  padding: 10px;
  color: #14171a;
}

.r-side-nav:hover {
  background: #e1e8ed;
  border-radius: 5px;
  padding: 10px;
  color: #14171a;
  cursor: pointer;
}

.r-side-nav-active {
  background: #e1e8ed !important;
  border-radius: 0px !important;
  padding: 10px !important;
  color: #333 !important;
}

.side-nav-search-input-field {
  width: 100% !important;
  padding: 10px;
  color: #14171a;
}

.side-nav {
  width: 100% !important;
  padding: 10px;
  color: #14171a;
}

.side-nav:hover {
  background: #e1e8ed;
  border-radius: 0px;
  padding: 10px;
  color: #14171a;
  cursor: pointer;
}

.side-nav-active {
  background: #e1e8ed !important;
  border-radius: 0px !important;
  padding: 10px !important;
  color: #333 !important;
}

.h-divider {
  width: 100%;
  border-bottom: thin solid #ececec;
  margin-bottom: 10px;
  margin-top: 10px;
}

.trash-icon {
  display: none;
}

.mobile-browse-btn {
  display: none;
}

@media screen and (max-width: 1366px) {
  .map_iframe {
    height: 57vh;
  }
  .side-nav-container-host {
    height: calc(100vh - 149px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
  }
  .media-container-basket-mobile {
    width: 50px !important;
    height: 50px !important;
  }

  .item-attributes {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 998px) {
  .side-nav-container-host {
    height: calc(100vh - 73px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
  }
  .trash-icon {
    display: block;
  }

  .food-kind-label {
    display: none;
  }

  .logo-special-div {
    display: flex;
    justify-content: center;
  }

  .media-container,
  .media-container-home {
    height: 140px;
  }
  .media-container-search {
    height: 130px !important;
  }

  .mobile-icon-container-menu {
    position: relative;
  }
  .basket_count {
    position: absolute;
    bottom: 10px;
    right: 35px;
    color: #fff;
    background: red;
  }

  .media_in_count {
    position: absolute;
    bottom: 10px;
    right: 35px;
    background: #ececec;
  }

  .sizes-displays {
    max-height: 50px;
    overflow-y: auto;
  }

  .notifications-belt-see:hover {
    box-shadow: none;
    border-radius: 10px;
    cursor: pointer;
  }

  .page_logo {
    width: 150px;
  }

  .profile_logo {
    width: 150px;
    height: 150px;
    /* position: absolute;
    bottom: -60px; */
    z-index: 100;
  }
}

@media screen and (max-width: 650px) {
  /* .media-container-basket-mobile {
    width: 30px !important;
    height: 30px !important;
  } */

  .page_logo {
    width: 150px;
  }

  .profile_logo {
    width: 150px;
    height: 150px;
    /* position: absolute;
    bottom: -90px; */
    /* left: calc(100% - 70%); */
    z-index: 100;
  }

  .side-nav-container-host {
    height: calc(100vh - 73px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
  }

  .mask-div label {
    background: #000;
    color: #fff;
    padding: 7px 10px 7px 10px;
    border-radius: 3px;
  }
  .max-height-300 {
    max-height: none;
  }

  .mobile-browse-btn {
    display: block;
  }
  .desktop-browse-btn {
    display: none;
  }
  .total_amount {
    text-align: left !important;
  }
  .browse-right-padding {
    padding-right: 5px !important;
  }
  .browse-left-padding {
    padding-left: 5px !important;
  }
  .browse-w-100 {
    width: 100% !important;
  }
  .basket-cols {
    display: none;
  }
  .trash-icon {
    display: block;
  }

  .logo-special-div {
    display: flex;
    justify-content: center;
  }

  .media-container {
    height: 110px;
  }

  .media-container-home {
    height: 110px !important;
  }
  .text-align-left {
    text-align: left !important;
  }

  .food-kind-label {
    display: none;
  }

  .search-field-active {
    margin-top: -30px !important;
  }

  .media-container-search {
    height: 140px !important;
  }

  .checkout-fields-left {
    padding: 0px 0px 0px 0px !important;
  }

  .checkout-fields-right {
    padding: 0px 0px 0px 0px !important;
  }

  .sub-live-search-results {
    max-height: none;
    padding: 0px !important;
    box-shadow: none;
    border: hidden;
    border-radius: 0px;
    border-top: hidden;
    border-bottom: thin solid #ececec;
    position: absolute;
    left: 0px;
    background: #fff;
    z-index: 2000 !important;
  }

  .search-results-padding {
    border-radius: 10px !important;
  }

  .search-button-mobile {
    background: #fff;
    padding-top: 0px !important;
    border: hidden !important;
  }

  .email-container-my-profile {
    margin-bottom: 15px;
  }

  .media-container-orders {
    height: 150px !important;
    border-radius: 5px !important;
  }

  .update_address_margin_right {
    margin-right: 0px;
  }

  .mobile-icon-container-menu {
    position: relative;
  }
  .basket_count {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #fff;
    background: red;
  }

  .media_in_count {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #ececec;
  }

  .order_notifications {
    display: none;
  }
  .mobile_order_notifications {
    padding: 10px;
    height: 40px;
    width: 40px;
    background: #333;
    color: #fff;
    z-index: 1000;
    position: fixed;
    right: 5px;
    top: 5px;
    border-radius: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    background: rgb(0, 0, 0, 0.8);
  }

  .notifications-belt-see:hover {
    box-shadow: none;
    border-radius: 10px;
    cursor: pointer;
  }

  .tip-text {
    display: block;
  }

  .btn-w-100 {
    width: 100%;
  }
}
